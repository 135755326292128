exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-artist-statement-jsx": () => import("./../../../src/pages/artistStatement.jsx" /* webpackChunkName: "component---src-pages-artist-statement-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-writings-jsx": () => import("./../../../src/pages/writings.jsx" /* webpackChunkName: "component---src-pages-writings-jsx" */),
  "component---src-templates-image-gallery-template-jsx": () => import("./../../../src/templates/ImageGalleryTemplate.jsx" /* webpackChunkName: "component---src-templates-image-gallery-template-jsx" */),
  "component---src-templates-post-template-jsx": () => import("./../../../src/templates/PostTemplate.jsx" /* webpackChunkName: "component---src-templates-post-template-jsx" */),
  "component---src-templates-video-gallery-template-jsx": () => import("./../../../src/templates/VideoGalleryTemplate.jsx" /* webpackChunkName: "component---src-templates-video-gallery-template-jsx" */)
}

